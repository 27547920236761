import * as React from 'react';
import { Flex } from '../../../components/Flex';
import { styled } from 'shamrock-clover-ui';
import RateInput from './RateInput';
import SubmitRateButton from './SubmitRateButton';
import ReasonInput from './ReasonInput';
import FooterLinks from './FooterLinks';
import { useRateProQueryParams } from 'hooks/useQueryParams';
import { NewRateQuery } from '../ratesTypes';
import { useEffect } from 'react';

const FooterContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  padding: 16px;
  margin-top: 32px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  position: relative;
  gap: 16px;
`;

const DisabledOverlay = styled.div`
  height: -webkit-fill-available;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  opacity: 0.5;
  z-index: 999;
  width: -webkit-fill-available;
  margin-top: -15px;
  margin-left: -15px;
`;

const YourRatesText = styled.div`
  position: absolute;
  margin-top: -40px;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: ${({ theme }) => theme.colors.gray[70]};
`;

interface Props {
  hasRate: boolean;
  submitRate: (
    suggestedBuyRate: number,
    reason: string,
    customerRate: number,
    searchQuery: NewRateQuery,
  ) => Promise<void>;
  suggestedBuyRate: string | 0 | undefined;
  isSellRateFFOn: boolean;
}

const Footer = ({
  submitRate,
  hasRate,
  suggestedBuyRate,
  isSellRateFFOn,
}: Props) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      pickupPostalCode,
      deliveryCity,
      deliveryState,
      deliveryPostalCode,
      trailerType,
      customerCode,
    },
  } = useRateProQueryParams();

  const [userSuggestedBuyRate, setUserSuggestedBuyRate] = React.useState<
    number | null
  >(null);
  const [yourCustomerRate, setYourCustomerRate] = React.useState<number | null>(
    null,
  );
  const [suggestionReason, setSuggestionReason] = React.useState<string | null>(
    null,
  );

  const onClick = async () => {
    if (!isButtonDisabled) {
      await submitRate(
        userSuggestedBuyRate,
        suggestionReason || '',
        yourCustomerRate,
        {
          customerCode,
          pickupCity,
          pickupState,
          pickupPostalCode,
          deliveryCity,
          deliveryState,
          deliveryPostalCode,
          trailerType,
        },
      );
      setUserSuggestedBuyRate(null);
      setYourCustomerRate(null);
      setSuggestionReason(null);
    }
  };

  const suggestedBuyRateNumber = Math.round(Number(
    (suggestedBuyRate as string)?.replace(/[^0-9.-]+/g, ''),
  ));

  const rangeBottom = suggestedBuyRateNumber - 75;
  const rangeTop = suggestedBuyRateNumber + 75;
  const isReasonDisabled =
    !userSuggestedBuyRate ||
    (userSuggestedBuyRate >= rangeBottom && userSuggestedBuyRate <= rangeTop);

  const isFooterDisabled =
    customerCode === undefined ||
    pickupCity === undefined ||
    deliveryCity === undefined ||
    trailerType === undefined ||
    hasRate === false;

  const suggReasonNotSet =
    suggestionReason === undefined ||
    suggestionReason === null ||
    suggestionReason.length < 0;

  const isButtonDisabled =
    isFooterDisabled ||
    (customerCode.length !== 7 && customerCode.length !== 8) ||
    yourCustomerRate === undefined ||
    yourCustomerRate === null ||
    yourCustomerRate.toString() === 'e' ||
    isNaN(yourCustomerRate) ||
    userSuggestedBuyRate === undefined ||
    userSuggestedBuyRate === null ||
    isNaN(userSuggestedBuyRate) ||
    (isReasonDisabled === false && suggReasonNotSet);

  useEffect(() => {
    if (isReasonDisabled) {
      setSuggestionReason(null);
    }
  }, [isReasonDisabled]);

  useEffect(() => {
    if (isSellRateFFOn)
      setUserSuggestedBuyRate(
        suggestedBuyRateNumber ? suggestedBuyRateNumber : null,
      );
    setYourCustomerRate(null);
    setSuggestionReason(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedBuyRate, suggestedBuyRateNumber]);

  return (
    <>
      <FooterContainer flexWrap="wrap">
        {isFooterDisabled && <DisabledOverlay />}
        <YourRatesText>YOUR RATES</YourRatesText>
        <RateInput
          type="SuggestedBuy"
          rate={userSuggestedBuyRate}
          setRate={setUserSuggestedBuyRate}
        />
        <ReasonInput
          selectedReason={suggestionReason}
          setReason={setSuggestionReason}
          isFooterDisabled={isFooterDisabled}
          isDisabled={isReasonDisabled}
        />
        <RateInput
          type="YourCustomer"
          rate={yourCustomerRate}
          setRate={setYourCustomerRate}
        />
        <SubmitRateButton disableButton={isButtonDisabled} onClick={onClick} />
      </FooterContainer>
      <FooterLinks />
    </>
  );
};

export default Footer;
