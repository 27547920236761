import { FC, useContext } from 'react';
import { Drawer, Spinner } from 'shamrock-clover-ui';
import CarrierProfileDrawerHeader from './CarrierProfileDrawerHeader';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import { ContentContainer, SpinnerContainer } from './DrawerStyles';
import DrawerSubHeader from './DrawerSubheader';
import { CarrierPaginationProps, CompanyInfoDrawerProps, SnackbarProps } from 'features/carrierCapacity/CapacityTypes';
import CompanyDetails from './CompanyDetails';
import DetailsTable from './DetailsTable';
import AddEditLanesDrawer from './AddEditLanesDrawer';
import { CarrierLoadHistory } from './CarrierLoadHistory';
import { Snackbar } from 'shamrock-clover-ui';
import { stopBodyScrolling } from 'features/carrierCapacity/utils/capacityUtils';

const CompanyInfoDrawer: FC<CompanyInfoDrawerProps> = ({
  isOpen,
  setIsOpen,
  selectedRowIndex,
  searchCapacity,
}) => {
  const {
    lanesAndCapacity,
    isLoading,
    openAddLane,
    openEditLane,
    carriers,
    snackbarNotification,
    carrierPagination,
    drawerTabClicked,
    handleAddLaneDrawer,
    handleEditLaneDrawer,
    handleOnCloseAddLane,
    handleOnCloseEditLane,
    paginationHandler,
    setSnackbarNotification,
    setCarrierPagination,
    setDrawerTabClicked,
    setCarrierIndexTaken,
  } = useContext(CarrierCapacityContext);

  const companyInfo =
    lanesAndCapacity[
      carrierPagination.carrierIndex !== undefined
        ? carrierPagination.carrierIndex
        : selectedRowIndex
    ] ||
    carriers[
      carrierPagination.carrierIndex !== undefined
        ? carrierPagination.carrierIndex
        : selectedRowIndex
    ];

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => {
          paginationHandler({
            type: 'next',
            carrierIndex: 0,
          });
          setCarrierIndexTaken(null);
          setIsOpen(false);
          setSnackbarNotification({} as SnackbarProps);
          setCarrierPagination({} as CarrierPaginationProps);
          // When the drawer closes, we need to enable scrolling on the body
          stopBodyScrolling(false);
        }}
        headerSeparatorType="none"
        width={'1161'}
        overlay={true}
        drawerHeaderContent={
          <CarrierProfileDrawerHeader
            companyInfo={companyInfo}
            {...(searchCapacity && { searchCapacity: true })}
            currentCarrierIndex={carrierPagination.carrierIndex}
            handlePagination={paginationHandler}
          />
        }
      >
        <ContentContainer>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner size='100'/>
            </SpinnerContainer>
          ) : (
            <> <DrawerSubHeader
            drawerTabClicked={drawerTabClicked}
            setTabClicked={setDrawerTabClicked}
          />
          {drawerTabClicked === 'Details' && (
            <>
              <CompanyDetails data={companyInfo} />
              <DetailsTable
                actionHandler={handleAddLaneDrawer}
                editHandler={(index, source) => {
                  handleEditLaneDrawer(companyInfo.lanes[index], index, source);
                }}
              />
            </>
          )}
          {drawerTabClicked === 'Load History' && (
            <CarrierLoadHistory selectedRowIndex={selectedRowIndex} />
          )}
          {snackbarNotification.open &&
            snackbarNotification.type !== 'error' && (
              <Snackbar
                message={snackbarNotification.message}
                open={snackbarNotification.open}
                variant="info"
              />
            )}</>
          )}
        </ContentContainer>
      </Drawer>
      {snackbarNotification.open && snackbarNotification.type === 'error' && (
        <Snackbar
          message={snackbarNotification.message}
          open={snackbarNotification.open}
          variant={snackbarNotification.type}
        />
      )}
      <AddEditLanesDrawer
        open={openAddLane}
        onClose={handleOnCloseAddLane}
        companyInfo={companyInfo}
      />
      <AddEditLanesDrawer
        open={openEditLane}
        editLane={true}
        onClose={handleOnCloseEditLane}
        companyInfo={companyInfo}
      />
    </>
  );
};

export default CompanyInfoDrawer;
