import { Flex } from 'components/Flex';
import { FC } from 'react';
import { Button, Dialog, styled } from 'shamrock-clover-ui';
import { ShowDialogOnDeleteLane } from '../CapacityTypes';

const DialogContainer = styled.div`
  [role='document'] {
    margin-bottom: 36px;
  }
`;

const DialogDescriptionText = styled.p<{ lineHeight: boolean }>`
  line-height: ${({ lineHeight }) => (lineHeight ? '20px' : '30px')};
  padding-top: 15px;
`;

const DeleteLaneCapacityDialog: FC<{
  showDialog: boolean;
  dialogTitle: string;
  dialogDescription: string;
  deletingCapacity: ShowDialogOnDeleteLane | null;
  handleCloseDialog: () => void;
  handleDelete: () => void;
}> = ({
  showDialog,
  dialogTitle,
  dialogDescription,
  deletingCapacity,
  handleCloseDialog,
  handleDelete,
}) => {
  return (
    <DialogContainer>
      <Dialog
        open={showDialog}
        title={dialogTitle}
        content={
          <DialogDescriptionText lineHeight={deletingCapacity === 'capacity'}>
            {dialogDescription}
          </DialogDescriptionText>
        }
        width="532px"
        height="295px"
        closeOnBackdropClick={false}
        actions={
          <Flex justifyContent="space-between" width="100%">
            <Button
              width="74px"
              buttonStyle="outlined"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              width="72px"
              buttonStyle="solid"
              color="red"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Flex>
        }
      />
    </DialogContainer>
  );
};

export default DeleteLaneCapacityDialog;
