import { FC } from 'react';
import { CompanyDetailsProps } from '../../CapacityTypes';
import useSnackbar from 'hooks/useSnackbar';
import { formatDate } from 'utils/dateUtils';
import {
  ColumnContainer,
  DetailsContainer,
  HeaderText,
  InfoColumn,
  InfoContainer,
  KeysText,
  QuickClipboardCopy,
  ValuesText,
} from './DrawerStyles';
import { getStateOrProvinceName } from 'utils/locations/abbreviationUtils';
import { truncateString } from 'features/carrierCapacity/utils/capacityUtils';
import { Tooltip } from 'shamrock-clover-ui';

const CompanyDetails: FC<CompanyDetailsProps> = ({ data }) => {
  const { carrier, lanes } = data;
  const { showSnackbar } = useSnackbar();
  const MAX_CHAR_LENGTH = 22;

  const handleCopyClick = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
    showSnackbar({
      message: `Copied to Clipboard`,
      type: 'success',
      duration: 1000,
    });
  };

  const getMcleodStatusTextColor = (mcleodStatus: string) => {
    switch (mcleodStatus) {
      case 'Active':
        return '#457528';
      case 'Inactive':
        return '#CC5F00';
      case 'No Dispatch':
        return '#CC5F00';
      case 'Trial Carrier':
        return '#CC5F00';
      case '':
        return '#424242';
      default:
        return '#424242';
    }
  };

  // We need to find the most recently updated lane to display in the Status section
  let mostRecentlyUpdatedLane = lanes[0];
  lanes.forEach((lane) => {
    if (lane.updatedAt > mostRecentlyUpdatedLane.updatedAt) {
      mostRecentlyUpdatedLane = lane;
    }
  });

  return (
    <>
      <DetailsContainer>
        <ColumnContainer
          height="144px"
          width="298px"
          borderRight={true}
          padding="0 16px 28px 0"
        >
          <HeaderText>Company Info</HeaderText>
          <InfoContainer>
            <InfoColumn width="139px" height="107px">
              <KeysText>McLeod ID</KeysText>
              <KeysText>MC</KeysText>
              <KeysText>DOT</KeysText>
              <KeysText>Trucks</KeysText>
              <KeysText marginBottom="0px">McLeod Status</KeysText>
            </InfoColumn>
            <InfoColumn width="159px" height="107px">
              <ValuesText>{carrier.mcleodCarrierId || '-'}</ValuesText>
              <ValuesText>{carrier.mcNumber || '-'}</ValuesText>
              <ValuesText>{carrier.dotNumber || '-'}</ValuesText>
              <ValuesText>
                {carrier.trucks > 0 ? carrier.trucks : '-'}
              </ValuesText>
              <ValuesText
                color={getMcleodStatusTextColor(carrier.mcleodStatus)}
                marginBottom="0px"
              >
                {carrier.mcleodStatus || '-'}
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="148px"
          width="322px"
          borderRight={true}
          padding="0 16px 24px 16px"
        >
          <HeaderText>Contact</HeaderText>
          <InfoContainer>
            <InfoColumn width="132px" height="111px">
              <KeysText>Contact Name</KeysText>
              <KeysText>Phone Number</KeysText>
              <KeysText>Email</KeysText>
              <KeysText>HQ</KeysText>
              <KeysText marginBottom="0px">Address</KeysText>
            </InfoColumn>
            <InfoColumn width="156px" height="111px">
              <ValuesText>{carrier.contactName || '-'}</ValuesText>
              <ValuesText>{carrier.contactPhone || '-'}</ValuesText>
              {carrier.contactEmail.length > MAX_CHAR_LENGTH ? (
                <Tooltip
                  title={carrier.contactEmail}
                  placement="top"
                  style={{ zIndex: 100000 }}
                >
                  <ValuesText>
                    <QuickClipboardCopy
                      onClick={() => {
                        handleCopyClick(carrier.contactEmail);
                      }}
                    >
                      {truncateString(carrier.contactEmail, MAX_CHAR_LENGTH)}
                    </QuickClipboardCopy>
                  </ValuesText>
                </Tooltip>
              ) : (
                <ValuesText>{carrier.contactEmail || '-'}</ValuesText>
              )}
              <ValuesText>
                {carrier.headquartersStateAbbr
                  ? getStateOrProvinceName(carrier.headquartersStateAbbr)
                  : '-'}
              </ValuesText>
              <ValuesText marginBottom="0px">
                {carrier.contactAddress || '-'}
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="172px"
          width="399px"
          borderRight={false}
          padding="0 0 0 16px"
        >
          <HeaderText>Status</HeaderText>
          <InfoContainer>
            <InfoColumn width="163px" height="89px">
              <KeysText>12 Month Load History</KeysText>
              <KeysText>CAM</KeysText>
              <KeysText>RTS Pro Account</KeysText>
              <KeysText marginBottom="0px">Active Users</KeysText>
            </InfoColumn>
            <InfoColumn width="225px" height="89px">
              <ValuesText>{carrier.oneYearLoadCount || '-'}</ValuesText>
              {carrier.camContactEmail === '' ? (
                <ValuesText>-</ValuesText>
              ) : carrier.camContactEmail.length > MAX_CHAR_LENGTH ? (
                <Tooltip
                  title={carrier.camContactEmail}
                  placement="top"
                  style={{ zIndex: 100000 }}
                >
                  <ValuesText>
                    <QuickClipboardCopy
                      onClick={() => {
                        handleCopyClick(carrier.camContactEmail);
                      }}
                    >
                      {truncateString(carrier.camContactEmail, MAX_CHAR_LENGTH)}
                    </QuickClipboardCopy>
                  </ValuesText>
                </Tooltip>
              ) : (
                <ValuesText>
                  <QuickClipboardCopy
                    onClick={() => {
                      handleCopyClick(carrier.camContactEmail);
                    }}
                  >
                    {carrier.camContactEmail}
                  </QuickClipboardCopy>
                </ValuesText>
              )}
              <ValuesText>{carrier.rtsProAccount}</ValuesText>
              <ValuesText marginBottom="0px">
                {carrier.activeUserCount || '-'}
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
          <InfoContainer>
            <InfoColumn width="91px" height="38px">
              <KeysText marginBottom="0px">Last Update:</KeysText>
            </InfoColumn>
            <InfoColumn width="308px" height="38px">
            {lanes.length > 0 ? (
              <>
                <KeysText marginBottom="0px">
                  {mostRecentlyUpdatedLane.updatedBy}
                </KeysText>
                <KeysText>
                  {formatDate(mostRecentlyUpdatedLane.updatedAt)}
                </KeysText>
              </>
            ) : (
              <KeysText paddingLeft="4px">-</KeysText>
            )}
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
      </DetailsContainer>
    </>
  );
};

export default CompanyDetails;
